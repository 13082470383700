@charset "UTF-8";

// Flex

// 横組み
.flex-box {
  display: flex;
  flex-flow: row wrap;
}
// 縦組み
.flex-column {
  display: flex;
  flex-flow: column wrap;
}

/* ++++++++++++++++++++++++++++++ カラムの揃え方 */

// 横組み
.align-end {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }


// 縦組み  旧Android iOS6以前は未対応 */
.align-end-vertical {
  align-content: flex-end; }

.align-center-vertical {
  align-content: center; }

.align-justify-vertical {
  align-content: space-between; }

.align-spaced-vertical {
  align-content: space-around; }


/* 子要素の天地の揃え align-itemsは親要素・align-selfは子要素 */
.item-top {
  align-items: flex-start; }
.item-top-child {
  align-self: flex-start; }

.item-end {
  align-items: flex-end; }
.item-end-child {
  align-self: flex-end; }

.item-center {
  align-items: center; }
.item-center-child {
  align-self: center; }

.item-stretch {
  align-items: stretch; }
.item-stretch-child {
  align-self: stretch; }

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- order
.order-0to1 {
  order: 0;
  @include media(lg) {
    order: 1;
  }
}
.order-1to0 {
  order: 1;
  @include media(lg) {
    order: 0;
  }
}

@for $i from 0 through 11 {
	.order-#{$i} {
		order: #{$i};
	}
}