@charset "UTF-8";

.breadlist-wrapper {
  background: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2em;
}
.breadlist {
  display: flex;
  background: transparent;
  font-size: 0.725em;
  list-style: none;
  padding: 1.2em 0;
  // clearfix
  &::after {
    display: table;
    clear: both;
    content: "";
  }
  & li {
    line-height: 1.1;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & + li::before {
      padding-right: .5em;
      padding-left: .5em;
      color: $gray;
      content: "＞";
    }

    & a {
      color: $link-color;
      &::active, &::visited {
        color: inherit;
      }
    }

    &.active {
      color: $gray;
    }
  }
}
