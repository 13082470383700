@charset "UTF-8";

// NEWS ELEMENT
.news-element {
  background: #fff;
  height: 100%;
  position: relative;

  & > a {
    color: $gray;
    display: block;
    height: 100%;
    transition: background .25s;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: #edf9f9;
      //color: #fff;
    }
  }

  & .news-category {
    background: $base-color;
    color: #fff;
    display: table;
    font-size: 87.5%;
    float: right;
    padding: 0.2em 0.6em;
    position: absolute;
    top: -5%;
    right: 0.5em;
    z-index: 1;
  }

  & .news-img {
    overflow: hidden;
    position: relative;
    & img {
      width: auto;
    }
  }

  & .news-element-body {
    line-height: 1.4;
    padding: 1em;
    &:after {
      content: '\f138';
      color: $gray-lighter;
      font-family: FontAwesome;
      font-size: 1.6em;
      display: block;
    }

    & .date {
      color: $second-color;
      font-size: 87.5%;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: .4em;
      & span {
        font-size: 140%;
      }
    }
  }
}

// TOPICS ELEMENT
.topics-element {
  background: #fff;
  height: 100%;
  position: relative;

  & > a {
    border-top: 4px solid $base-color;
    color: #333;
    display: block;
    height: 100%;
    padding-top: 1em;
    transition: background .25s;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: $base-color;
      border-top: 4px solid lighten($base-color, 20%);
      color: #fff;
    }
  }

  & .topics-img {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    width: 140px;
    height: 140px;
    & img {
      //width: 100%;
      max-width: none;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    @include media(md) {
      width: 180px;
      height: 180px;
    }
  }

  & .topics-element-body {
    font-size: 0.875em;
    line-height: 1.5;
    padding: 1em;
    & .title {
      font-size: 1.25em;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: .4em;
      text-align: center;
    }
  }
}

// Information & News などに使える dl
// NEWS
dl.news {
  & dt {
    display: block;
    color: $base-color;
    @include media(lg) {
      display: inline-block;
      float: left;
      clear: left;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & dd {
    color: $gray;
    margin-left: 0;
    margin-bottom: 0.75em;
    border-bottom: 1px dotted #cdcdcd;
    padding-bottom: 0.75em;
    @include media(lg) {
      margin-left: 7em;
      margin-bottom: 1em;
      padding-bottom: 1em;
    }
  }
}


// 重要なお知らせ 2020.03.19
.important-info-container {
  background: #fff;
  border: 1px solid $color-red;
  padding: 1.5em;
}

.important-info-list {
  font-size: 1.25em;
  li {
    &:not(:first-child) {
      margin-top: 1em;
    }
    a {
      display: block;
      color: $color-red;
      &:before {
        content: '\f1c1';
        font-family: FontAwesome;
        font-size: 1.2em;
        font-weight: 400;
        display: inline-block;
        margin-right: .4em;
      }
    }
  }
}

// Google ストリートビュー 2021.02.26
.street-view {
  width: 100%;
  height: 320px;
  overflow: hidden;
  @include media(lg){
    height: 480px;
  }
}
