@charset "UTF-8";

.hero {
  background: $base-color;
  color: inherit;
  //padding: 4em 0;
  //margin-bottom: 3.2em;
  width: 100%;

  .hero-inner {
    position: relative;

    & .main-title {
      color: #fff;
      font-size: 140%;
      line-height: 1.2;
      position: absolute;
      top: 50%;
      left: .5em;
      transform: translateY(-50%);
      @include media(md) {
        font-size: $h1-font-size * $h-scale-medium;
      }
      @include media(lg) {
        font-size: $h1-font-size * $h-scale-large;
      }
    }
  }
}
