@charset "UTF-8";

footer {
  flex: none;
  padding-top: 4em;
  width: 100%;
  min-width: 100%;
  z-index: $z-index-footer;
}

.footer-wrap {
  background: $footer-color;
  position: relative;
  & ul {
    justify-content: center;
    & a {
    }
    @include media(md) {
      justify-content: flex-end;
    }
  }
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .footer-logo {
    font-family: Helvetica, Arial Black, sans-serif;
    font-weight: 800;
    font-size: 1.6rem;
    & a {
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
    @include media(lg) {
      font-size: 2.0rem;
    }
  }
}

.footer-middle {
  padding: 1em 0;

  & ul {
    justify-content: center;
    & a {
      color: $gray-light;
    }
    @include media(lg) {
      justify-content: start;
    }
  }
}

.footer-lower {
  border-top: 1px solid $gray-lighter;
}


// コピーライト
.copyright {
  background: transparent;
  color: $gray-light;
  display: block;
  font-size: 72.5%;
  text-align: center;
  padding: .8em 0;
  width: 100%;
  @include media(lg) {
    text-align: right;
  }
}
