@charset "UTF-8";

@mixin media($mq, $md: md, $lg: lg) {
 
  $w1    : map-get($grid-breakpoints, $md);
  $w2    : map-get($grid-breakpoints, $lg);
  $min1  : 'min-width: #{convert-em($w1)}';
  $min2  : 'min-width: #{convert-em($w2)}';
 
  @if $mq == $md {
    @media screen and ($min1) {
      @content;
    }
  }
  @else if $mq == $lg {
    @media screen and ($min2) {
      @content;
    }
  }
}
 
@function convert-em($width) {
  @return '#{$width / 16}em';
}
