@charset "UTF-8";

.block {
	border: 1px solid $base-color;

  &.center {
    text-align: center;
  }

  &.second {
    border-color: $second-color;
    //color: $second-color;
  }
  &.third {
    border-color: $third-color;
    //color: $third-color;
  }
  &.fourth {
    border-color: $fourth-color;
    //color: $fourth-color;
  }
  &.five {
    border-color: $five-color;
    //color: $five-color;
  }
}

.block.height {
  height: 100%;
}

.block-header {
  background: $base-color;
  //border-radius: 3px 3px 0 0;
  color: #fff;
  font-size: inherit;
  font-weight: bold;
  padding: .3em 1em;

  .second & {
    background: $second-color;
  }
  .third & {
    background: $third-color;
  }
  .fourth & {
    background: $fourth-color;
  }
  .five & {
    background: $five-color;
  }
}

.block-body {
  color: inherit;
  padding: 1em;

  & .grid {
    margin: (- container-padding-grid(sm));
    @include media(md) {
      margin: 0 (- container-padding-grid(md));
    }
    @include media(lg) {
      margin: 0 (- container-padding-grid(lg));
    }
  }
}
