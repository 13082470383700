@charset "UTF-8";

// Mixin Grid

@mixin make-grid($width) {
  flex: 0 0 $width;
  width: $width;
	max-width: $width;
}

