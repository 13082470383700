@charset "UTF-8";

ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

// UL
//

ul.disc {
  list-style: disc outside;
  padding-left: 1em;
  & ul {
    list-style: circle outside;
    padding-left: 1em;
  }
}

ul.square {
  & li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    //&:not(:last-child) {
    //  margin-bottom: 0.8em;
    //}
    &:before {
      content: '■';
      color: $base-color;
      margin-right: 0.4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.circle {
  & li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    //&:not(:last-child) {
    //  margin-bottom: 0.8em;
    //}
    &:before {
      content: '●';
      color: $base-color;
      margin-right: 0.4em;
      position: absolute;
      left: 0;
    }
  }
}

.list-horizon {
  display: flex;
  flex-flow: row wrap;
  & li:not(:last-child) {
    padding-right: 1em;
  }
}

.footer-company {
  & li {
    font-size: .8em;
    &.name {
      font-size: 1.2em;
    }
    &.tel, &.fax {
      font-size: 1em;
      & i {
        color: $second-color;
      }
    }
  }
}


// OL
//

ol.num {
  list-style: decimal outside;
  padding-left: 1em;
  & > li {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
  & ol {
    list-style: decimal outside;
    padding-left: 1em;
  }
}

.schedule {
  font-size: 1.2em;
  text-align: center;
  & li {
    background: #F0F8F4;
    border: 1px solid #98D1B5;
    padding: .5em 0;
    position: relative;
    & + li {
      margin-top: 4rem;
      @include media(lg) {
        margin-top: 5rem;
      }
      &:before {
        font-family: FontAwesome;
        content: '\f13a';
        color: #77B8DA;
        display: block;
        font-size: 1.2em;
        position: absolute;
        top: -3.6rem;
        left: 50%;
        transform: translateX(-50%);
        @include media(lg) {
          top: -4.5rem;
        }
      }
    }
    & span {
      color: $base-color;
      display: inline-block;
      font-weight: bold;
      margin-right: .6em;
    }
  }
}


// DL
//

dl {
  & dt {
    font-weight: bold;
    &:not(:first-child) {
      margin-top: 1em; }
  }
  & dd {
    line-height: 1.4;
  }
}

// テーブル組
//
dl.table {
  &:after {
    content:" ";
    display:block;
    clear:both;
  }
  & dt {
    float: left;
    width: 50%;
  }
  & dd {
    &:after {
      content:" ";
      display:block;
      clear:both;
    }
  }
}

.dl-horizon {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  &.dt-block {
    display: block;
  }
  & dt, dd {
    line-height: inherit;
  }
  & dt {
    text-align: right;
    margin-right: 1em;
    width: 18%;
    &:not(:first-child) {
      margin-top: 0;
    }
    @include media(md) {
      width: 10%;
    }
  }
  & dd {
    flex: 1 0 1%;
    margin-bottom: 1em;
    //width: 82%;
    @include media(md) {
    //  width: 90%;
    }
  }
}

