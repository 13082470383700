@charset "UTF-8";


// メニューボタン
.slicknav_btn {
  display: inline-flex;
  align-content: center;
  align-items: center;
  background: transparent;
  height: 100%;
  //padding: 0 1rem;
  position: relative;
  &:hover {
    text-decoration: none;
  }
}

.slicknav_menutxt { // ボタン内のテキスト
  display: block;
  color: $gray-dark;
  margin-right: 0.4em;
}

.slicknav_icon { // バーガー用ボーダー
  display: block;
  width: 24px;
  height: 18px;

  & span {
    width: 100%;
    height: 2px; // 太さ
    background-color: $gray-dark;
    display: block;
    transition: all .4s;

    &:nth-of-type(1) {
      top: 10px;
    }
    &:nth-of-type(2) {
      margin: 6px 0; // 間隔
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.slicknav_open .slicknav_icon { // バーガー用ボーダー OPEN時
  background: transparent;

  & span {
    &:nth-of-type(1) {
      transform: translateY(8px) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-8px) rotate(45deg);
    }
  }
}

// リスト
.slicknav_nav,
.slicknav_nav ul {
  overflow: hidden;
}
.slicknav_nav ul,
.slicknav_nav li {
  display: block;
}

.slicknav_nav {
  color: $gray-dark;
  font-size: 1.1em;
  position: absolute;
  top: $height-header-sm;
  right: 0;
  width: 100%;
  z-index: 99;

  & ul { // 子メニュー
    display: flex;
    flex-flow: row wrap;
    & li {
      flex: 1 0 50%;
      position: initial;
      & a {
        background: darken($base-color, 10%);
        font-size: 1.4rem;
        padding: 1em;
        & span span {
          display: block;
        }
      }
      & ul { // 孫メニュー
        & li {
          & a {
          }
        }
      }
    }
  }

  & > li {
    text-align: center;
    &.slicknav_parent a.slicknav_item {
      position: relative;
      &:after { // 子メニューの矢印
        font-family: FontAwesome;
        font-size: 1.6em;
        content: '\f107';
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);
      }
      & .slicknav_arrow { display: none; } // 元の矢印を消去
    }
  }

  & a {
    display: block;
    padding: 2rem 1rem;
    color: #fff;
    background: $base-color;
    transition: background .2s;
    &.slicknav_item a {
      background: transparent;
    }

    &:hover {
      background: darken($base-color, 15%);
      //color: #fff;
    }
    &.contact {
      background: #fdec54;
      color: $base-color;
      &:hover {
          background: darken(#fdec54, 25%);
        }
    }
  }

  & .slicknav_txtnode { // リンクなし
    padding: 1rem;
  }

  & .slicknav_item a {
    padding: 0;
    margin: 0;
  }

  & .slicknav_parent-link a {
    padding: 0;
    margin: 0;
  }
}

