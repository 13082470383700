@charset "UTF-8";

// ナビゲーションの wrap
.global-navigation {
  position: relative;
}


// ナビゲーションとバーガーメニューの可視
nav.global,
.g-navi {
  display: none;
  @include media(lg) {
    display: block;
  }
}
.nav-button,
.slicknav_menu {
  display: block;
  z-index: 100;
  @include media(lg) {
    display: none;
  }
}


// PC用ナビゲーション
.g-navi {
  background: transparent;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  & > li {
    flex: 1;
    text-align: center;
    position: relative;
    line-height: 1;
    &:not(:first-child) {
      border-left: 1px dotted $gray-light;
    }

    & > a {
      //background: $base-color;
      color: #333;
      display: block;
      font-size: 1.6rem;
      padding: 1.25em 0;
      transition: color .25s;
      &:hover {
        color: lighten($base-color, 20%);
        text-decoration: none;
      }
    }
    & ul.sub-menu {
      display: block;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 57px;
      left: 50%;
      transform: translateX(-50%);
      transition: .3s visibility linear, .3s opacity linear;
      width: 150%;
      &:before { // 吹き出しの口
        position: absolute;
        content: '';
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border: 8px solid transparent;
        border-bottom: 8px solid rgba(#000, 0.8);
        width: 0;
        height: 0;
      }
      & li {
        background: rgba(#000, 0.8);
        transition: background .25s;
        //&:first-child {
        //  border-radius: 6px 6px 0 0;
        //}
        //&:last-child {
        //  border-radius: 0 0 6px 6px;
        //}
        &:hover {
            background: rgba(#000, 0.85);
        }
        & a {
          color: #fff;
          display: block;
          font-size: 1.3rem;
          padding: 1.8em;
          & span {
            transition: .2s linear;
          }
          &:hover {
            text-decoration: none;
            & span {
              border-bottom: 2px solid $base-color;
              padding-bottom: .6em;
            }
          }
        }
      }
    }
    &:hover ul.sub-menu {
      visibility: visible;
      opacity: 1;
    }
  }
}

// slicknav スマホ用の読み込み
@import "vendor/slicknav";


// side-column in menu
.side-menu {
  background: #fff;
  width: 100%;
  & li {
    line-height: 1.4;
    position: relative;
    width: 90%;
    margin: 0 auto;
    &:before {
      content: '';
      background: $base-color;
      display: inline-block;
      width: 3px;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:not(:last-child) {
      border-bottom: 1px solid $gray-lighter;
    }
    & a {
      color: $gray;
      display: block;
      padding: 1em;
      transition: background .2s;
      &:hover {
        background: #F9F9FA;
        text-decoration: none;
      }
    }
    & span {
      display: inline;
      @include media(lg) {
        display: block;
      }
    }
  }
}

// nav 内のバナーエリア
.banner-area {
  width: 100%;
  margin: 2em auto;
  @include media(md) {
    width: 100%;
  }
  @include media(lg) {
    width: 200px;
  }
}

