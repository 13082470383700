@charset "UTF-8";


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & a {
    border: 1px solid $gray-light;
    border-radius: 3px;
    color: #333;
    display: block;
    min-width: 32px;
    padding: 0.4rem 0.8rem;

    &:hover {
      border: 1px solid lighten($gray-light, 15%);
      }
  }
  & .active {
    background: $base-color;
    color: #fff;
  }

  & span {
    color: $gray-light;
    display: block;
    margin: 0 4px;
  }

  & li {
    margin: 0 2px;
  }

  & ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
  }
}
