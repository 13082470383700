@charset "UTF-8";


// containerの幅

@function width($key) {
  @return map-get($container-width, $key)/10+rem;
}

// container の padding

@function container-padding($key) {
  @return map-get($container-padding, $key)/10+rem;
}

@function container-padding-grid($key) {
  @return map-get($container-padding, $key) / 10 / 2 + rem;
}

// サイドカラムの横幅

$width-right-column: ($width-right-column / 10)+rem;
$width-left-column: ($width-left-column / 10)+rem;

// メインカラムとサイドカラムの余白

$main-side-margin: ($main-side-margin / 10)+rem;

// ベースフォントサイズ

@function font-size($key) {
  @return map-get($base-font-size, $key)/10+rem;
}

// ベースフォントサイズの数字のみ

@function font-size-num($key) {
  @return map-get($base-font-size, $key)/10;
}

// 段落などの余白

@function paragraph-margin($key) {
  @return map-get($paragraph-margin, $key)+em;
}
