@charset "UTF-8";

@for $i from 1 through 25 {
  .mt#{$i * 4} {
    margin-top: #{($i * 4) / 10}rem !important;
    @include media(lg) {
      margin-top: #{($i * 4) / 10 * 1.5}rem !important; }
  }
  .mb#{$i * 4} {
    margin-bottom: #{($i * 4) / 10}rem !important;
    @include media(lg) {
      margin-bottom: #{($i * 4) / 10 * 1.5}rem !important; }
  }
  .mr#{$i * 4} {
    margin-right: #{($i * 4) / 10}rem !important;
    @include media(lg) {
      margin-right: #{($i * 4) / 10 * 1.5}rem !important; }
  }
  .ml#{$i * 4} {
    margin-left: #{($i * 4) / 10}rem !important;
    @include media(lg) {
      margin-left: #{($i * 4) / 10 * 1.5}rem !important; }
  }
  .pt#{$i * 4} {
    padding-top: #{($i * 4) / 10}rem !important;
    @include media(lg) {
      padding-top: #{($i * 4) / 10 * 1.5}rem !important; }
  }
  .pb#{$i * 4} {
    padding-bottom: #{($i * 4) / 10}rem !important;
    @include media(lg) {
      padding-bottom: #{($i * 4) / 10 * 1.5}rem !important; }
  }
  .pr#{$i * 4} {
    padding-right: #{($i * 4) / 10}rem !important;
    @include media(lg) {
      padding-right: #{($i * 4) / 10 * 1.5}rem !important; }
  }
  .pl#{$i * 4} {
    padding-left: #{($i * 4) / 10}rem !important;
    @include media(lg) {
      padding-left: #{($i * 4) / 10 * 1.5}rem !important; }
  }
}

.m0 {
  margin: 0 !important;
}
