@charset "UTF-8";

.panel {
  background-color: #fff;
  border: 1px solid $gray-lighter;
  border-radius: 0px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  &.height {
    height: 100%;
  }
}

.panel-header {
  padding: 0.75em 1em;
  border-bottom: 1px solid $gray-lighter;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.panel-body {
  padding: 1em;
}

.panel-footer {
  padding: 0.5em 1em;
  background-color: #f5f5f5;
  border-top: 1px solid $gray-lighter;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
