@charset "UTF-8";

@function fs-ribbon($key) {
  @return map-get($base-font-size, $key);
}

.ribbon {
  background-color: $base-color;
  border-radius: 0px;
  color: #fff;
  display: inline-block;
  font-size: inherit;
  text-align: center;
  margin: .5em 0;
  padding: .3em 1em;
  position: relative;
  
  &.round {
    border-radius: 2em;
  }

  &.deco {
    height: fs-ribbon(sm) * 2.4 + px;
    line-height: fs-ribbon(sm) * 2.4 + px;
    padding: 0 fs-ribbon(sm)+(fs-ribbon(sm) * 2.4 * .3)+px 0 fs-ribbon(sm)+px;
    &::after {
      content: '';
      position: absolute;
      width: 0px;
      height: 0px;
      z-index: 1;
      top: 0;
      right: 0;
      border-width: (fs-ribbon(sm) * 2.4 / 2)+px (fs-ribbon(sm) * 2.4 * .3)+px (fs-ribbon(sm) * 2.4 / 2)+px 0px;
      border-color: transparent #fff transparent transparent;
      border-style: solid;
    }
    @include media(lg) {
      height: fs-ribbon(lg) * 2.4 + px;
      line-height: fs-ribbon(lg) * 2.4 + px;
      padding: 0 fs-ribbon(lg)+(fs-ribbon(lg) * 2.4 * .3)+px 0 fs-ribbon(lg)+px;
      &::after {
        border-width: (fs-ribbon(lg) * 2.4 / 2)+px (fs-ribbon(lg) * 2.4 * .3)+px (fs-ribbon(lg) * 2.4 / 2)+px 0px;
      }
    }
  }

  &.second {
    background-color: $second-color; }
  &.third {
    background-color: $third-color; }
  &.fourth {
    background-color: $fourth-color; }
  &.five {
    background-color: $five-color; }

  &.outline {
    background: #fff;
    border: 1px solid $base-color;
    color: $base-color;

    &.second {
      border-color: $second-color;
      color: $second-color; }
    &.third {
      border-color: $third-color;
      color: $third-color; }
    &.fourth {
      border-color: $fourth-color;
      color: $fourth-color; }
    &.five {
      border-color: $five-color;
      color: $five-color; }
  }
}
