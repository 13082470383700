@charset "UTF-8";

header {
  width: 100%;
  //position: fixed;
  top: 0;
  z-index: $z-index-header;
}

.header-wrap {
  background: transparent;
  border-bottom: 1px solid #ddd;
  border-top: 4px solid $base-color;
  position: relative;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $height-header-sm;
  @include media(lg) {
    height: $height-header-lg;
  }

  & a {
      color: $gray-dark;
      &:hover {
        text-decoration: none;
      }
    }

  & .header-logo {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.6rem;
    @include media(lg) {
      font-size: 2.0rem;
      //margin-right: 1.5em;
    }
    img {
        width: 6rem;
        margin-right: .5em;
        @include media(lg) {
          width: 7.2rem;
        }
      }
    & .logo-text {
      line-height: 1.4;
      & .text-sub {
        color: $gray-light;
        font-size: 64%;
      }
      & .text-name {
        color: $base-color;
        font-weight: bold;
      }
    }
  }
}

.header-address {
  display: none;
  @include media(lg) {
    display: block;
    white-space: nowrap;
    & p {
      font-size: 1.2rem;
      margin: 0 0 .2em;
      text-align: center;
      letter-spacing: .35em;
    }
    & .header-address-inner {
      display: flex;
      align-items: center;
      line-height: 1.2;
      & figure {
        margin-right: 2rem;
        & img { width: 5.2rem; height: 5.2rem; }
      }
      & address, & em { font-style: normal; }
      & address {
        font-size: 3rem;
      }
      & em {
        display: block;
        font-size: 1.3rem;
        font-weight: normal;
        letter-spacing: .07em;
      }
    }
  }
}
