@charset "UTF-8";

.tabs-nav {
  display: flex;
  font-weight: bold;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 4px solid $second-color;
  & > li {
    //color: #07D;
    position: relative;
    width: 50%;
    //transition: .3s ease-out;
    &:nth-child(n+2) {
      margin-left: .5em;
    }
    & a {
      background: $gray-lightest;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      color: $second-color;
      display: block;
      padding: .8rem 1.2rem;
      transition: background-color 0.25s ease-out;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
    &.active a {
      //border-bottom: 4px solid $base-color;
      background: $second-color;
      color: #fff;
    }
  }
}
.tabs-nav > li:not(.active) a:hover,
.tabs-nav > li:not(.active) a:focus {
  background: darken($second-color, 10%);
  //border-bottom: 4px solid $base-color;
  color: #fff;
  outline: none;
}

.tabs-content {
  background: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 1em;
}
