@charset "UTF-8";

// メインレイアウト
//
// 1:1カラム / 2:2カラム

$main-layout: 2 !default;

// 2カラム時にサイドカラムをどちらに配置するか。使用する方に ture。
$side-right: false !default;
$side-left:  true !default;


// Breakpoints
//
// 表示していないが単位は px

$grid-breakpoints: (
  'md': 768,
  'lg': 960,
) !default;


// メインコンテンツの横幅
//
// 表示していないが単位は px

$width-main: 832 !default;


// サイドカラムの横幅。
//
// 単位は px

$width-right-column: 280 !default;
$width-left-column:  242 !default;


// メインカラムとサイドカラムの間隔
//
// 単位はpx

$main-side-margin: 36;


// container の左右の padding
//
// 単位はpx

$container-padding-lg: 28 !default; // 2カラム時の横幅の計算のため、'lg' のみ変数

$container-padding: (
  'sm': 16,
  'md': 20,
  'lg': $container-padding-lg,
  ) !default;


// メインコンテンツの横幅
//
// レイアウトカラム数に応じて横幅が自動で決まる

@if $main-layout == 3 {
    $width-main: $width-main + $main-side-margin + $width-right-column + $width-left-column + $container-padding-lg * 2;
  } @else if $main-layout == 2 and $side-right == true and $side-left == false {
      $width-main: $width-main + $main-side-margin + $width-right-column + $container-padding-lg * 2;
  } @else if $main-layout == 2 and $side-left == true and $side-right == false {
     $width-main: $width-main + $main-side-margin + $width-left-column + $container-padding-lg * 2;
  } @else {
    $width-main: $width-main;
  }


// Container Width
//
// .container の最大値。メインコンテンツの横幅になる。単位はpx。

$container-width: (
  'md': 768,
  'lg': $width-main,
  ) !default;


// ベースのカラム数
//
// デザイン用コンテンツのカラム数。

$grid-columns: 12 !default;


// ベースの文字サイズ
//
// 入力単位は px、出力単位は rem。

$base-font-size: (
  'sm': 14,
  'md': 14,
  'lg': 16,
  ) !default;


// Grid System の margin と padding
//
//

//$base-gutter:  1rem;                          // ベース
//$base-gutter-sm: $base-gutter * .8 !default;  // sm
//$base-gutter-md: $base-gutter * 1  !default;  // md
//$base-gutter-lg: $base-gutter * 1.2 !default; // lg


// 段落の余白
// 単位は em。
//

$paragraph-margin: (
  'sm': 1.2,
  'md': 1.6,
  'lg': 2,
  ) !default;


// <section> 同士の余白
//
//

$section-margin: 4em;


// headerの高さ
//
//

$height-header-sm: 5rem;
$height-header-lg: 12rem;


// z-index
//
//

$z-index-header:   2 !default;
$z-index-contents: 0 !default;
$z-index-footer:   1 !default;


// Typography
//
// Font family
//
//

$font-family: "YakuHanJPs", -apple-system, BlinkMacSystemFont, "Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,arial,sans-serif;
//$font-family: "YakuHanJPs", -apple-system, BlinkMacSystemFont, Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//$font-family: "YakuHanJPs", Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic,  "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//$font-family: "YakuHanJPs", Arial, "メイリオ", Meiryo, sans-serif;


// line-height
//
//

$base-line-height: 1 !default;
$base-line-height-sm: $base-line-height * 1.6;
$base-line-height-md: $base-line-height * 1.7;
$base-line-height-lg: $base-line-height * 1.8;


// 見出し用フォントサイズ
//
//

$h1-font-size: 2.0em;
$h2-font-size: 1.8em;
$h3-font-size: 1.6em;
$h4-font-size: 1.4em;
$h5-font-size: 1.2em;
$h6-font-size: 1.0em;
$h-scale-medium: 1.15;    // medium用
$h-scale-large:  1.1875;  // large用


// Fontawesome
//
//
$icon-arrows:  '\f138'; // 丸あり f138 丸なし  f054
$icon-pdf:     '\f1c1';
$icon-window:  '\f2d2';
$icon-access:  '\f239'; // 交通アクセス
$icon-contact: '\f199'; // お問い合わせ


// Color
//
//

$body-color:    #333    !default; // デフォルトテキストカラー
$body-bg:       #f7fafc !default; // デフォルトバックグラウンドカラー
$header-color:  #f1eef5 !default; // header background color
$footer-color:  #fff !default; // footer background color

$base-color:    #009bb9 !default;
$second-color:  #f0ad4e !default;
$third-color:   #317ae1 !default;
$fourth-color:  #9fdfb0 !default;
$five-color:    #d32f2f !default;

$gray-dark:     #333333 !default;
$gray:          #55595c !default;
$gray-light:    #818a91 !default;
$gray-lighter:  #dfe2e4 !default;
$gray-lightest: #eceeef !default;

$color-white:  #fff    !default;
$color-black:  #000    !default;
$color-red:    #b0120a !default;
$color-yellow: #fff000 !default; // Y100

$link-color:                 #0033cc !default;
$link-decoration:            none !default;
$link-hover-color:           darken($link-color, 15%) !default;
$link-hover-decoration:      underline !default;

$table-border-color: #ccc !default; // Tableのボーダーの色
