@charset "UTF-8";


table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	padding: 0;
	margin: 0 0 1em;

  & th, td {
    border: 1px solid $table-border-color;
    padding: 0.5em 1em;
  }

  & th {
    background: #FBFBF6;
  }
  & td {
    background: #fff;
  }
}

table.clear {
  margin: 0;
  & th, td {
    background: #fff;
    border: none;
    padding: 0;
  }
  &.dayservice {
    width: 20rem;
    @include media(lg) {
      width: 24rem;
    }
    & th, td {
      padding: .2em 0;
    }
    & th {
      font-weight: normal;
      text-align: left;
    }
    & td {
      text-align: right;
    }
  }
  &.confirm {
    & th, td {
      display: block;
      @include media(md) {
        border-bottom: 24px solid #fff;
        display: table-cell;
      }
    }
    & th {
      background: #EBF3F9;
      color: $base-color;
      font-size: 1.2rem;
      font-weight: normal;
      text-align: left;
      padding: .2em 1em;
      width: 100%;
      @include media(md) {
        font-size: 1.3rem;
        width: 28%;
      }
      @include media(lg) {
        font-size: 1.4rem;
      }
    }
    & td {
      padding: 1em;
      @include media(md) {
        padding: .2em .5em .2em 1em;
      }
    }
  }
}

// スクロールを出すためのwrapper
.table-scroll {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    white-space: normal;
  }
}

table.td-center {
  & td {
    text-align: center;
    &.left {
      text-align: left;
    }
  }
}


// th block
.th-block {
  & th, td {
    display: block;
    @include media(md) {
      display: table-cell;
    }
  }
  & th {
    width: 100%;
    @include media(md) {
      width: 16%;
    }
  }
  & td {
    border-top: none;
    border-bottom: none;

    @include media(md) {
      border-top: 1px solid $table-border-color;
      border-bottom: 1px solid $table-border-color;
    }
  }
  & tr:last-child td {
      border-bottom: 1px solid $table-border-color;
  }

  &.th-w20 {
    & th {
      @include media(md) {
        width: 20%;
      }
    }
  }
}

// 所定疾患施設療養費の算定状況
.santei {
  & thead th {
    background: $base-color;
    color: #fff;
  }
  & td {
    text-align: center;
  }
}

// 営業案内
.catalog {
  & th, td {
    border: none;
    display: block;
    @include media(md) {
      //border-bottom: 1px solid $table-border-color;
      display: table-cell;
    }
  }
  & th {
    background: #F0F8F4;
    color: $base-color;
    width: 100%;
    @include media(md) {
      border-bottom: 1px solid #D6D8D8;
      text-align: right;
      width: auto;
    }
  }
  & td {
    border-top: none;
    border-bottom: none;
    text-align: center;

    @include media(md) {
      //border-top: 1px solid $table-border-color;
      border-bottom: 1px solid #D6D8D8;
      text-align: left;
    }
  }
  & tr:last-child td, tr:last-child th {
      border-bottom: none;
  }
}
