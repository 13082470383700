@charset "UTF-8";

// 段落の余白
p {
  & + p {
    margin-top: paragraph-margin(sm);
    @include media(md) {
      margin-top: paragraph-margin(md);
    }
    @include media(lg) {
      margin-top: paragraph-margin(lg);
    }
  }
  & + .grid {
    margin-top: paragraph-margin(sm);
    @include media(md) {
      margin-top: paragraph-margin(md);
    }
    @include media(lg) {
      margin-top: paragraph-margin(lg);
    }
  }
}

.element {
  margin: paragraph-margin(sm) 0;
  @include media(md) {
    margin: paragraph-margin(md) 0;
  }
  @include media(lg) {
    margin: paragraph-margin(lg) 0;
  }
}
