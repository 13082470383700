@charset "UTF-8";

// アイコン

.icon {
  & span {
    &::after {
      font-family: FontAwesome;
      content: $icon-arrows;
      color: inherit;
      display: inline-block;
      margin-left: .4em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        font-family: FontAwesome;
        content: $icon-arrows;
        color: inherit;
        display: inline-block;
        margin-right: .4em;
      }
    }
    &.pdf {
      &::after { content: $icon-pdf; }
      &.left  {
        &::after { content: none; }
        &::before { content: $icon-pdf; }
      }
    }
    &.nw {
      &::after { content: $icon-window; }
      &.left  {
        &::after { content: none; }
        &::before { content: $icon-window; }
      }
    }
  } // /span
  & span.ic-second {
    &::after, &::before {
      color: $second-color;
    }
  }
  & span.ic-third {
    &::after, &::before {
      color: $third-color;
    }
  }
  & span.ic-fourth {
    &::after, &::before {
      color: $fourth-color;
    }
  }
  & span.ic-five {
    &::after, &::before {
      color: $five-color;
    }
  }
}


// button
//
//
.button, .button.outline {
  & span {
    &::after {
      font-family: FontAwesome;
      content: $icon-arrows;
      color: inherit;
      display: inline-block;
      margin-left: .4em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        font-family: FontAwesome;
        content: $icon-arrows;
        color: inherit;
        display: inline-block;
        margin-right: .4em;
      }
    }
    &.pdf {
      &::after { content: $icon-pdf; }
      &.left  {
        &::after { content: none; }
        &::before { content: $icon-pdf; }
      }
    }
    &.nw {
      &::after { content: $icon-window; }
      &.left  {
        &::after { content: none; }
        &::before { content: $icon-window; }
      }
    }
  } // /span
  & span.ic-second {
    &::after, &::before {
      color: $second-color;
    }
  }
  & span.ic-third {
    &::after, &::before {
      color: $third-color;
    }
  }
  & span.ic-fourth {
    &::after, &::before {
      color: $fourth-color;
    }
  }
  & span.ic-five {
    &::after, &::before {
      color: $five-color;
    }
  }
}