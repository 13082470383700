@charset "UTF-8";

// service
//

.service-heading {
  background: #5786A5;
  display: block;
  margin-bottom: 1.6em;

  @include media(md) {
    border: 1px solid #5786a5;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .service-heading-head {
    background: transparent;
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    padding: .4em .4em;
    text-align: center;
    @include media(md) {
      flex: 1 0 auto;
      font-size: 1.2em;
      text-align: left;
      padding: 0 0 0 1em;
    }
  }

  & .service-heading-btn {
    background: #EAF0F5;
    cursor: pointer;
    color: #5786A5;
    display: block;
    font-size: 1em;
    text-align: center;
    //line-height: 1 !important;
    -webkit-appearance: none;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    vertical-align: middle;
    padding: .5em;
    @include media(md) {
      line-height: inherit;
      padding: 0.4em 1em;
    }

    &:hover {
      background-color: darken(#EAF0F5,15%);
      color: #fff;
      text-decoration: none;
    }

    & span {
      &::after {
        font-family: FontAwesome;
        content: $icon-arrows;
        color: #5786A5;
        display: inline-block;
        margin-left: .4em;
      }
    }
  }
}


// form
//
.form-bg {
  background: #fff;
  padding: 1em;
  @include media(md) {
    padding: 2em;
  }
}

// ご応募・ご質問
//
.facility-info {
  margin: 1em auto;
  & dl {
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: .5em;
    padding-bottom: .5em;
    & dt {
      font-weight: normal;
    }
    & dd {
      font-size: 2em;
    }
  }

  & ul {
    display: table;
    font-size: 1.8em;
    margin: 0 auto;

    & i {
      color: $base-color;
    }

    & li {
      color: $gray-light;
      display: table;
      font-weight: bold;
      line-height: 1.6;
      &.mail {
        font-size: .6em;
        line-height: 2;
      }
    }
  }
}

// google map
//
#map_canvas {
  width: 100%;
  height: 320px;
  margin-bottom: 1em;
  @include media(md) {
    height: 540px;
  }
}

// 料金シミュレータ 計算結果
.result-box {
  text-align: center;
  & i {
    color: $base-color;
    font-size: 4em;
  }

  & div {
    background: $gray-lightest;
    padding: 1em 0;
  }

  & .result {
    background: none;
    border: none;
    color: #005E49;
    display: inline-block;
    font-size: 2em;
    height: auto;
    padding: 0;
    text-align: center;
    width: 50%;
    @include media(md) {
      width: 40%;
    }
  }
  & .result_day {
    background: none;
    border: none;
    color: inherit;
    display: inline-block;
    font-size: inherit;
    height: auto;
    padding: 0;
    width: auto;
  }
}

$result-fs: 2rem;
$result-color: $base-color;
input.result::-moz-placeholder { color: $result-color; font-size: $result-fs; @include media(md) { font-size: $result-fs*1.2; } @include media(lg) { font-size: $result-fs*1.4; } }
input.result:-ms-input-placeholder { color: $result-color; font-size: $result-fs; @include media(md) { font-size: $result-fs*1.2; } @include media(lg) { font-size: $result-fs*1.4; } }
input.result::-webkit-input-placeholder { color: $result-color; font-size: $result-fs; @include media(md) { font-size: $result-fs*1.2; } @include media(lg) { font-size: $result-fs*1.4; } }
input.result::-ms-expand { color: $result-color; font-size: $result-fs; @include media(md) { font-size: $result-fs*1.2; } @include media(md) { font-size: $result-fs*1.4; } }



// 広報誌
.public-grid-container {
  display: flex;
  flex-flow: row wrap;
  margin: -1em;
}

.public-column {
  flex: 0 0 50%;
  padding: 1em;
  @include media(md){
    flex: 0 0 25%;
  }
}

.public-inner {
  color: $gray-dark;
  display: block;
  text-align: center;
  &:hover {
    color: $gray-dark;
  }
}

.public-image {
  img {
    box-shadow: 0 .5rem 1rem rgba( 0,0,0, .15 );
  }
}

.public-title {
  margin-top: 1em;
}
